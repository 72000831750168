<template>
  <div class="main-rq">
    <div class="kpje">可开票金额：<span>￥ {{residue}}</span> <input type="button" value="申请发票" @click="addinvoice()"></div>
    <div class="for-order" v-for="(item, index) in tableData">
      <div class="order-rq">
        <div class="order-num">{{item.invoice_rise}}</div>
        <div class="order-status" v-if="item.status == '开票完成'"><i class="order-wc" style="color:#80d635;">开票完成 | <m class="showqrcode" @click="showqrcode(item.qrcode)"  style="text-decoration:underline;cursor: pointer;">下载发票</m></i></div>
        <div class="order-status" v-else><i class="order-wc">{{item.status}}</i></div>
      </div>
      <div class="order-rq">
        <div class="order-des">税号</div>
        <div class="order-data">{{item.duty_paragraph}}</div>
      </div>
      <div class="order-rq">
        <div class="order-des">发票金额</div>
        <div class="order-data"><i class="fa fa-rmb"></i> {{item.invoice_amount}}</div>
      </div>
      <div class="order-rq" style="margin-bottom: 0;height: 25px;">
        <div class="order-des">申请时间</div>
        <div class="order-data">{{item.create_time}}</div>
      </div>
    </div>
    <div v-show="total>pageSize" class="page-rq">
      <!--分页-->
      <el-pagination @change="getQueryParams" :page-size="pageSize" class="paging" small background :total="total" layout="prev, pager, next"></el-pagination>
    </div>
  </div>
  <Footers></Footers>
  <el-dialog v-model="centerDialogVisible" width="80%" center>
    <div style="width: 100%;">
      <img :src="dowenloadData" style="width: 100%;height: auto;">
    </div>
  </el-dialog>

  <el-drawer
      v-model="drawer"
      title="申请发票"
      direction="btt"
      size="100%"
      :show-close="true"
      :before-close="handleClose"
  >
    <fieldset class="layui-elem-field layui-field-title">
      <div class="alert alert-warning">
        <p>1、如申请发票，开票金额满250元，无需另付手续费，数电发票出票后通过扫描二维码进行下载；</p>
        <p>2、所申请发票为增值税普通发票；</p>
        <p>3、确认所提交信息正确无误，由此造成的损失键盘喵速排不承担责任；</p>
        <p>4、提交发票申请后，约7个工作日为您出票，请耐心等待；</p>
        <p>5、请保证信息真实性，否则无法为您开票；</p>
      </div>
      <el-space fill>
        <el-alert type="warning" :closable="false">
          <p>请确保公司名字准确无误，抬头填写错误造成打印发票错误的不可重开</p>
        </el-alert>
        <el-form-item label="发票抬头">
          <el-input v-model="formData.invoice_rise" />
        </el-form-item>
      </el-space>
      <el-space fill>
        <el-alert type="warning" :closable="false">
          <p>国家税务规定，企业必须填写“纳税人识别号/统一社会信用代码”，发票才能入账否则是废票。政府单位等没有税号的可填写事业法人登记证号。税号提供错误造成打印发票错误的不可重开</p>
        </el-alert>
        <el-form-item label="税号">
          <el-input v-model="formData.duty_paragraph" />
        </el-form-item>
      </el-space>
      <el-space fill>
        <el-alert type="warning" :closable="false">
          <p>请确保本次开票金额准确无误，金额填写错误造成打印发票错误的不可重开</p>
        </el-alert>
        <el-form-item label="发票金额">
          <el-input v-model="formData.invoice_amount" />
        </el-form-item>
      </el-space>
      <el-button type="warning" @click="sendData">提交</el-button>
    </fieldset>
  </el-drawer>

</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import {getInvoiceList,getMyInvoice} from "@/src/api/apiconfig/main";
import {getLocal} from "@/src/utils/local";
import {ElMessage} from "element-plus";
const router = useRouter();
const centerDialogVisible = ref(false) //显示发票图片的layer层状态
const residue = ref(0);// 可开票金额
const drawer = ref(false)//新增发票状态
//分页总条数
const total = ref(0)
const pageNumber = ref(1)
const tableData = ref([])
const pageSize = ref(Number(0))
const dowenloadData =ref('')
useHead({
  title:'我的发票',
  meta:[
    {hid:'keywords',name:'keywords','content':'我的发票'},
    {hid:'description',name:'description','content':'我的发票'},
  ]
})
const formData = reactive({
  invoice_rise:'',
  duty_paragraph:'',
  invoice_amount:''
})

if(!getLocal("token")){
  router.push('/mycenter')
}
//分页换页
const getQueryParams = ((number) => {
  pageNumber.value = number
  invoiceList()
  const container = document.querySelector("body")
  container.scrollTo(0, 0);
})
//开票历史列表(接口)
const invoiceList = (async ()=>{
  const response = await getInvoiceList({page:pageNumber.value})
  if(response.data.length!==0){
    tableData.value = response.data.list
    total.value = response.data.total
    pageSize.value = Number(response.data.pagesize)
    residue.value = Number(response.data.residue)
  }else{
    tableData.value = []
  }
})

const sendData =(async() =>{
  const response =  await getMyInvoice({
    invoice_rise:formData.invoice_rise,
    duty_paragraph:formData.duty_paragraph,
    invoice_amount:formData.invoice_amount,
  })
  if(response.code==0){
    ElMessage.error(response.msg)
  }else{
    ElMessage.success(response.msg)
    invoiceList();
    setTimeout(function(){
      drawer.value = false;
    },1000)
  }
  console.log(response,'response')
})

onMounted (()=>{
  invoiceList()
})
const showqrcode = (qrcodeurl:String) =>{
  centerDialogVisible.value = true;
  dowenloadData.value = qrcodeurl
}

const addinvoice = () =>{
  drawer.value = true;
}

</script>
<style>
.main-rq{
  padding: 15px;
  margin-bottom: 50px;
}
.kpje {
  height: 50px;
  background: #f1f3f9;
  text-align: left;
  padding-left: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  border-radius: 5px;
  line-height: 50px;
}
.kpje input {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  background-color: #da8743;
  border: 0;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}
.for-order {
  background: #f1f3f9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.order-rq {
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.order-num {
  width: 56%;
  float: left;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.order-status {
  width: 44%;
  float: left;
  text-align: right;
}
.order-status i {
  font-size: 12px;
  font-style: normal;
  padding: 7px 15px;
  border-radius: 15px;
  font-weight: bold;
}
.order-des{float: left;width: 30%;text-align: left;color: #666;}
.order-data{float: left;width: 70%;text-align: right;}
.page-rq{
  display: flex;
  justify-content: center;
  margin: 20px auto 70px auto;
}
.layui-field-title {
  margin: -22px 0 20px;
  border-width: 1px 0 0;
  border-color: #e6e6e6;
}
.alert {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #8a6d3b;
  background-color: #fcf8e3;
}
.el-drawer__body{
  padding: 0px;
}
</style>